// const SERVER_URL = 'https://api.truthdare.online:7777/';
// const SOCKET_URL = 'wss://api.truthdare.online:7777';
// const SERVER_URL = '192.168.100.122:5555';
const SERVER_URL = 'https://api.truthdare.online/';
const SOCKET_URL = 'wss://api.truthdare.online:5555';
const MUSIC_URL = 'https://music.truthdare.online';

export default {
  SERVER_URL,
  SOCKET_URL,
  MUSIC_URL,
};
