/* eslint-disable no-constructor-return */
import { io } from 'socket.io-client';
import keys from 'src/utils/keys';
import { getDeviceId } from 'src/utils/main';

class Socket {
  socket = io(`${keys.SOCKET_URL}/notifications`, {
    autoConnect: false,
  });

  connectWithAuthToken(token) {
    this.socket.io.opts.extraHeaders = {};
    this.socket.io.opts.extraHeaders.Authorization = `Bearer ${token}`;
    this.socket.io.opts.extraHeaders.Device = getDeviceId();

    if (!this.socket.connected) {
      this.socket.connect();
    }
  }

  get socketInfo() {
    return this.socket;
  }

  disconnect() {
    this.socket?.emit('leave');
    this.socket?.disconnect();
  }

  emit(event, data) {
    this.socket?.emit(event, data);
  }

  on(event, callBack) {
    if (this.socket) {
      this.socket?.off(event, callBack);
      this.socket?.on(event, callBack);
    }
  }

  off(event, callback) {
    this.socket?.off(event, callback);
  }
}

export default new Socket();
