// /news/get-meny-filtered?skip=0&teke=10&visible=

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAPI from 'src/services/AuthApi';

const initialState = {
  news: {
    list: [],
    count: 0,
  },
};

export const getNews = createAsyncThunk(
  'news/getNews',
  async (search) => {
    const response = await AuthAPI.get(`/news/get-meny-filtered${search}`);

    return response.data;
  },
);

export const deleteNews = createAsyncThunk(
  'news/deleteNews',
  async (id) => {
    const response = await AuthAPI.delete(`news/delete/${id}`);

    return response.data;
  },
);

export const addNews = createAsyncThunk(
  'news/addNews',
  async (data) => {
    const response = await AuthAPI.post('/news/add', data);

    return response.data;
  },
);

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action) => {
      const [list, count] = action.payload.response;

      state.news = { list, count };
    });
  },
});

export const getNewsData = (state) => state.news.news;

export default newsSlice.reducer;
