import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import connectToSocket from 'src/socket/connectToSocket';
import AuthAPI from 'src/services/AuthApi';
import API from 'src/services/Api';

const initialState = {
  users: [],
  reports: {
    status: 'loading',
    list: [],
  },
  userInfo: null,
  playersQuestions: {
    list: null,
    count: 0,
  },
  bunnedUsers: {
    list: null,
    count: 0,
  },
  bonusInfo: {},
  saleDays: [],
};

export const authLogin = createAsyncThunk(
  'users/authLogin',
  async (data) => {
    const { email, password } = data;
    const response = await API.post('/auth/login', {
      email,
      password,
    });

    data.cb(response.data.token);

    return response.data;
  },
);

export const getMe = createAsyncThunk(
  'users/getMe',
  async () => {
    const response = await AuthAPI.get('/user/me');

    return response.data;
  },
)

export const sendNewsletter = createAsyncThunk(
  'users/sendNewsletter',
  async (data) => {
    const response = await AuthAPI.post('/message/send/from-admin', data);

    return response.data;
  },
)

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (search) => {
    const response = await API.get(`/user/all${search}`);

    return response.data;
  },
);

export const getBunnedUsers = createAsyncThunk(
  'users/getBunnedUsers',
  async (search) => {
    const response = await AuthAPI.get(`/user/ban/${search}`);

    return response.data;
  },
);

export const createTransaction = createAsyncThunk(
  'users/createTransaction',
  async (data) => {
    const response = await AuthAPI.post('/user/money-transaction', data);

    return response.data;
  },
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (data) => {
    const response = await API.put(`/user/${data.id}`, data);

    return response.data;
  },
);

export const getReports = createAsyncThunk(
  'users/getReports',
  async (search) => {
    const response = await API.get(`/report${search}`);

    return response.data;
  },
);

export const setReportViewed = createAsyncThunk(
  '/users/setReportViewed',
  async ({ reportId, cb }) => {
    const response = await API.post('report/viewed', {
      reportId,
    });

    cb();

    return response.data;
  },
)

export const blockUser = createAsyncThunk(
  '/users/blockUser',
  async (id) => {
    const response = await AuthAPI.post(`/user/ban/${id}`);

    return response.data;
  },
);

export const answerPlayerQuestion = createAsyncThunk(
  '/users/answerPlayerQuestion',
  async ({ id, message }) => {
    const response = await AuthAPI.post(`/support/answer-from-support/${id}`, {
      message,
      imgUrl: '',
    });

    return response.data;
  },
);

export const setReadedQuestion = createAsyncThunk(
  '/users/setReadedQuestion',
  async (data) => {
    const response = await AuthAPI.put('/support/set-readed', data);

    return response.data;
  },
);

export const getPlayerQuestions = createAsyncThunk(
  '/users/getPlayerQuestions',
  async (search) => {
    const response = await AuthAPI.get(`/support/chat-list${search}`);

    return response.data;
  },
)

export const getSaleDays = createAsyncThunk(
  '/users/getSaleDays',
  async () => {
    const response = await AuthAPI.get('/options/by-id/1');

    return response.data;
  },
);

export const getBonusInfo = createAsyncThunk(
  '/users/getBonusInfo',
  async () => {
    const response = await AuthAPI.get('/options/by-id/2');

    return response.data;
  },
);

export const setOptions = createAsyncThunk(
  '/users/setOptions',
  async (data) => {
    const response = await AuthAPI.put('/options/update', data);

    return response.data;
  },
)

export const blockUserDevice = createAsyncThunk(
  '/users/blockUserDevice',
  async (id) => {
    const response = await AuthAPI.post('/device/block-by-id', {
      userId: id,
    });

    return response.data;
  },
)

export const unblockUser = createAsyncThunk(
  '/users/unblockUser',
  async (id) => {
    const response = await AuthAPI.post('/device/unblock-by-id', {
      userId: id,
    });

    return response.data;
  },
)

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    resetUserInfo: (state) => {
      state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    builder.addCase(createTransaction.rejected, () => {
      toast.error('Money not added');
    });
    builder.addCase(createTransaction.fulfilled, () => {
      toast.success('Money was successfully added');
    });

    builder.addCase(setReportViewed.pending, (state) => {
      state.reports.status = 'loading';
    });
    builder.addCase(getReports.pending, (state) => {
      state.reports.status = 'loading'
    });
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.reports.status = 'loaded';
      state.reports.list = action.payload
    });
    builder.addCase(authLogin.fulfilled, (state, action) => {
      localStorage.setItem('user-info', JSON.stringify(action.payload.user));

      state.userInfo = action.payload.user;

      connectToSocket(localStorage.getItem('admin_access_token'));
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      localStorage.setItem('user-info', JSON.stringify(action.payload.user));

      state.userInfo = action.payload.user;

      connectToSocket(localStorage.getItem('admin_access_token'))
    });

    builder.addCase(getPlayerQuestions.fulfilled, (state, action) => {
      const [list, count] = action.payload.response;

      state.playersQuestions.list = list;
      state.playersQuestions.count = count;
    })

    builder.addCase(getPlayerQuestions.pending, (state) => {
      state.playersQuestions.list = null;
      state.playersQuestions.count = 0;
    })

    builder.addCase(getSaleDays.fulfilled, (state, action) => {
      state.saleDays = action.payload.response.data;
    })

    builder.addCase(getBunnedUsers.pending, (state, action) => {
      state.bunnedUsers.list = null;
    })

    builder.addCase(getBunnedUsers.fulfilled, (state, action) => {
      state.bunnedUsers = action.payload.response;
    })

    builder.addCase(getBonusInfo.pending, (state, action) => {
      state.bonusInfo = null;
    });

    builder.addCase(getBonusInfo.fulfilled, (state, action) => {
      state.bonusInfo = action.payload.response;
    })
  },
});

export const {
  resetUserInfo,
} = usersSlice.actions;

export const getUsersInfo = (state) => state.users.users;
export const getUserInfo = (state) => state.users.userInfo;
export const getBonusDate = (state) => state.users.bonusInfo;
export const getReportsInfo = (state) => state.users.reports;
export const getSaleDaysInfo = (state) => state.users.saleDays;
export const getBunnedUsersInfo = (state) => state.users.bunnedUsers;
export const getPlayerQuestionsInfo = (state) => state.users.playersQuestions;

export default usersSlice.reducer;
