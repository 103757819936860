import { v4 as uuid } from 'uuid'

const languagesList = [
  { name: 'Все', code: '' },
  { name: 'Русский', code: 'RU' },
  { name: 'Английский', code: 'EN' },
  { name: 'Испанский', code: 'ES' },
  { name: 'Японский', code: 'JA' },
  { name: 'Китайский', code: 'CN' },
  { name: 'Корейский', code: 'KO' },
  { name: 'Французский', code: 'FR' },
  { name: 'Индонезийский', code: 'ID' },
  { name: 'Хинди', code: 'HI' },
  { name: 'Турецкий', code: 'TR' },
];

export const getDeviceId = () => {
  let deviceId = localStorage.getItem('DEVICE_ID_KEY') || '';

  if (!deviceId) {
    deviceId = uuid();
    localStorage.setItem('DEVICE_ID_KEY', deviceId);
  }

  return deviceId;
}

export default languagesList;
